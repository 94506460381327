import Vue from 'vue'
import VueRouter from 'vue-router'
import contentSettings from '@/config/config-app'

Vue.use(VueRouter)

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  scrollBehavior() {
    return { x: 0, y: 0 }
  },
  routes: [
    {
      path: '/',
      name: 'home',
      beforeEnter(to, _, next) {
        if (contentSettings.requireHomepagePassword) {
          const publicPages = ['/login'];
          const authRequired = !publicPages.includes(to.path);
          const isLoggedIn = localStorage.password === 'Ly9EZW1vMzYwPQ==' ? true : false
        
          if (authRequired && !isLoggedIn) {
            router.replace('/login')
          }
        }
        next()
      },
      component: () => import('@/views/Home.vue')
    },
    {
      path: '/brand',
      redirect: '/'
    },
    {
      path: '/brand/:brand',
      name: 'brand',
      component: () => import('@/views/Home.vue')
    },
    {
      path: '/login',
      name: 'login',
      component: () => import('@/views/Login.vue'),
    },
  ],
})

// router.beforeEach((to, _, next) => {
//   const publicPages = ['/login'];
//   const authRequired = !publicPages.includes(to.path);
//   const isLoggedIn = localStorage.isLoggedIn === 'true' ? true : false

//   if (authRequired && !isLoggedIn) {
//     router.replace('/login')
//   }

//   next()
// });

// ? For splash screen
// Remove afterEach hook if you are not using splash screen
router.afterEach(() => {
  // Remove initial loading
  const appLoading = document.getElementById('loading-bg')
  if (appLoading) {
    appLoading.style.display = 'none'
  }
})

export default router
